<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'app',
  components: {},
  created() {
    console.log(window.location.href, 11)
    // 强制https
    let url = window.location.href;
    if (url.indexOf("https") < 0) {
      url = url.replace("http:", "https:");
      window.location.replace(url);
    }
  },
}
</script>

<style>

</style>
