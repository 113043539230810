<template>
<div class="Brandmall">


<!-- Banner -->

    <div class="industry_banner">
        <img src="../assets/images/bg12.png" alt="">
        <span><img src="../assets/images/index12.png" alt=""></span>
    </div>

 <!-- Banner End -->

    <div class="container">
      <div class="JoinIn_market">
        <div class="index_brand_title">
          <strong><em>market</em> demand</strong>
          <p>市场需求</p>
        </div>
        <div class="JoinIn_con" data-aos="fade-up">
          <p>中国目前乘用车保有量已超2.8亿辆；中国共有40万家社会维修企业，2万家4S店；
2020年汽车后市场实现交易额超1万亿元；</p>
          <i><img src="../assets/images/bg13.png" alt=""></i>
        </div>

      </div>
    </div>

    <div class="JoinIn_demand_big">
      <div class="container">
        <div class="JoinIn_demand">
          <div class="index_brand_title">
            <strong><em>market</em> prospect</strong>
            <p>市场前景</p>
          </div>

          <div class="JoinIn_demand_con">
            <div class="JoinIn_demand_left" data-aos="fade-up">
              <p>1、区域独家代理，保障长期获利。</p>
              <p>2、进退自如，进可以加大投资，扩大生意边界；退可以库存变现，随时实现自由货币化。</p>
              <p>3、采购无忧，一站式匹配汽配全品类。</p>
              <p>4、销售无忧。平台统一销售，专业队伍助力渠道开发</p>
              <p>5、物流仓储配送一体化，利用强大的物联网技术、科学仓配。</p>
              <p>6、售后无忧。五星服务标准，一体化服务机制，由平台客户和驻地售后服务代表一对一上门服务。</p>
            </div>
            <div class="JoinIn_demand_right" data-aos="fade-up">
              <img src="../assets/images/bg14.png" alt="">
            </div>
            <div class="clear"></div>

          </div>
        </div>
      </div>
    </div>


    <div class="container">
      <div class="JoinIn_franchise">
          <div class="index_brand_title">
            <strong><em>Franchise</em> standard</strong>
            <p>加盟标准</p>
          </div>
          <div class="JoinIn_franchise_ul">
            <ul>
              <li data-aos="fade-up"><em><img src="../assets/images/bg15.png" alt=""></em></li>
              <li data-aos="fade-up" class="down">
                <em><img src="../assets/images/bg16.png" alt=""></em>
                <samp>
                  <p>在当地有固定经营场所</p>
                  <p>和营业执照有一定行业影响力</p>
                </samp>
                <i></i>
              </li>

              <li data-aos="fade-up"><em><img src="../assets/images/bg32.png" alt=""></em></li>
              <li data-aos="fade-up" class="up">
                <em><img src="../assets/images/bg16.png" alt=""></em>
                <samp>
                  <p>在当地有固定经营场所</p>
                  <p>和营业执照有一定行业影响力</p>
                </samp>
                <i></i>
              </li>

              <li data-aos="fade-up"><em><img src="../assets/images/bg33.png" alt=""></em></li>
              <li data-aos="fade-up" class="up">
                <em><img src="../assets/images/bg16.png" alt=""></em>
                <samp>
                  <p>在当地有固定经营场所</p>
                </samp>
                <i></i>
              </li>
              <div class="clear"></div>
            </ul>
          </div>
      </div>
    </div>

</div>
</template>

<script>
export default {
  name: 'index',
  data(){
    return{

    }
  },
  methods:{
    damoa(){
        AOS.init({
            duration : 300,
            easing: 'ease-in-sine',  
            delay: 300
        })   
    }
  },
   mounted(){
      this.damoa()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.industry_banner img{width: 100%;}

.JoinIn_market{padding: 70px 0;}

.index_brand_title{width: 100%;margin: 30px auto; text-align: center;}
.index_brand_title strong{font-size: 24px; color: #0072FF; text-transform:uppercase}
.index_brand_title strong em{font-weight: normal; color: #999999;}
.index_brand_title p{font-size: 18px; color: #0072FF;}

.JoinIn_con p{font-size: 16px; line-height: 28px; text-indent: 2em; margin-bottom: 50px; text-align:center; display: block;}
.JoinIn_con i img{width: 100%;}

.JoinIn_demand_big{padding: 70px 0; background: #f8f8f8;}
.JoinIn_demand_big .JoinIn_demand_con{margin-top: 20px;}
.JoinIn_demand_big .JoinIn_demand_con .JoinIn_demand_left{width: 65%; float: left; margin-right: 2%; margin-top: 45px;}
.JoinIn_demand_big .JoinIn_demand_con .JoinIn_demand_left p{font-size: 16px; color: #333333; line-height: 28px; text-indent: 2em; margin-bottom: 10px;}

.JoinIn_demand_big .JoinIn_demand_con .JoinIn_demand_right{width: 33%; float: left;}
.JoinIn_demand_big .JoinIn_demand_con .JoinIn_demand_right img{width: 100%;}

.JoinIn_franchise{padding: 30px 0 80px 0;}

.JoinIn_franchise .JoinIn_franchise_ul{padding-top: 20px;}
.JoinIn_franchise .JoinIn_franchise_ul li{ width: 33.3%; float: left; position: relative; height: 100%;}
.JoinIn_franchise .JoinIn_franchise_ul li em img{width: 100%;}
.JoinIn_franchise .JoinIn_franchise_ul li samp{position: absolute; top: 0; left: 0; display: flex; flex-direction: column; justify-content: center; width: 100%; height: 100%; right: 0; margin: 0px auto; text-align: center;}

.JoinIn_franchise .JoinIn_franchise_ul li samp p{font-size: 16px; color: #FFFFFF; margin-bottom: 5px;}

.JoinIn_franchise .JoinIn_franchise_ul .down i{
        width: 0px;              
				height: 0px;
				border: 8px solid #0072FF;
				border-bottom-color: transparent; 
				border-left-color: transparent;
				border-right-color: transparent;
        position: absolute;
        bottom: -16px;
        left: 0;
        right: 0;
        margin: 0px auto;
        z-index: 3;
}

.JoinIn_franchise .JoinIn_franchise_ul .up i{
        width: 0px;              
				height: 0px;
				border: 8px solid #0072FF;
				border-top-color: transparent; 
				border-left-color: transparent;
				border-right-color: transparent;
        position: absolute;
        top: -16px;
        left: 0;
        right: 0;
        margin: 0px auto;
        z-index: 3;
}

.industry_banner span img{display: none;}


@media(max-width:1200px){
.JoinIn_market{padding: 50px 15px;}
.JoinIn_demand_big{padding: 50px 15px;}
.JoinIn_franchise{padding: 20px 15px 30px 15px;}

.JoinIn_demand_big .JoinIn_demand_con .JoinIn_demand_left{margin-top: 0;}
}


@media(max-width:1000px){
.JoinIn_demand_big .JoinIn_demand_con .JoinIn_demand_left{width: 60%;}
.JoinIn_demand_big .JoinIn_demand_con .JoinIn_demand_right{width: 38%;}
}
@media(max-width:800px){
  .index_brand_title strong{font-size: 18px;}
  .index_brand_title p{font-size: 14px;}
  .JoinIn_con p{margin-bottom: 30px;}
  .JoinIn_demand_big .JoinIn_demand_con .JoinIn_demand_left p{font-size: 14px; line-height: 26px; text-indent: 0;}
  .JoinIn_demand_big .JoinIn_demand_con .JoinIn_demand_left{width: 100%;}
  .JoinIn_demand_big .JoinIn_demand_con .JoinIn_demand_right{width: 100%;}

  .JoinIn_franchise .JoinIn_franchise_ul li samp p{font-size: 14px; }

}
@media(max-width:700px){
  .JoinIn_franchise .JoinIn_franchise_ul li{width: 50%; margin-bottom: 30px;}
  .JoinIn_franchise .JoinIn_franchise_ul li i{display: none;}
  .JoinIn_franchise .JoinIn_franchise_ul li samp p{font-size: 12px; padding: 0 15px;}
  .JoinIn_con p{margin-bottom: 10px;}
}
@media(max-width:600px){
  .JoinIn_con p{font-size: 14px;}
  .JoinIn_market{padding: 30px 15px;}
  .index_brand_title{margin: 10px 0;}
  .index_brand_title strong{font-size: 16px;}
  .JoinIn_demand_big{padding: 30px 15px;}
  .JoinIn_franchise{padding: 20px 15px 20px 15px ;}

  .industry_banner img{display: none;}
  .industry_banner span img{display: block;}
}

</style>
