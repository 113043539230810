<template>
<div class="Brandmall">


<!-- Banner -->

    <div class="industry_banner">
        <img src="../assets/images/bg04.png" alt="">
        <span><img src="../assets/images/index13.jpg" alt=""></span>
    </div>
   
 <!-- Banner End -->
    <div class="container">
        <div class="IndustryDetails_big">
            <div class="IndustryDetails_left">
                <a href="javascirpt:;" @click="clickFh()"><img src="../assets/images/bg06.png" alt=""></a>
            </div>

            <div class="IndustryDetails_right">
                <div class="IndustryDetails_right_title">
                    <h2>开思获5000万美元C2轮融资，大湾区共同家园领投。</h2>
                    <em>本轮融资将继续用于技术研发、平台基础设施建设、市场业务拓展等方面。</em>
                    <i>2020年06月22日</i>

                    <div class="page02" data-aos="fade-left">
                        <a href="javascript:;" class="page02_pave"><i class="el-icon-arrow-left"></i></a>
                        <a href="javascript:;" class="page02_next"><i class="el-icon-arrow-right"></i></a>
                    </div>
                </div>

                <div class="IndustryDetails_right_con">
                    <p>6月22日，中国汽车后市场企业“开思”宣布完成5000万美元C2轮融资，本轮融资由大湾区共同家园投资有限公司领投。据悉，本轮融资将继续用于技术研发、平台基
础设施建设、市场业务拓展等方面。</p>
                    <p>开思成立于2015年，已多次获得包括红杉资本中国基金、顺为资本、源码资本、复星锐正、华业天成、沣源资本等机构的青睐与数轮投资。</p>
                    <p>开思致力于建设汽车后市场的科技基础设施，与产业链上下游共建行业标准和数字化的信用体系，推动汽车后市场数字化转型。开思业务涵盖一站式汽配交易平台“开
思汽配”、智慧汽修门店管理系统“1号车间”、供应链金融、汽配物流等。
</p>
                    <p>开思旗下一站式汽配交易平台“开思汽配”，通过重构汽配交易链条，解决了配件交易的效率问题。“AI+大数据”智能搜索引擎、VIN码解析器、智能交易匹配，让汽
车零配件的交易效率大幅提升。同时，建立了汽配交易的标准和信用体系。通过联结优质的源头供应商，开思建立了配件有质保、假劣必赔的品质承诺，提供包括技术
服务在内的售后体系，让汽配交易更放心。另外，开思还通过数据服务和供应链金融，帮助供应商提高库存周转率、资金使用效率。
</p>
                    <p>泰合资本董事总经理付超平表示：中国平均车龄进入黄金期，万亿车后市场拐点降临，而传统交易基础设施薄弱。开思依托数据驱动，科技赋能上下游，极大提升了产
业链各环节效率并定义汽车配件交易标准与信任体系，已在数据、产品、商业化等各维度全面领跑行业，期待开思更快更好的发展，继续推动全产业链升级。
</p>
                    <p>目前，开思的业务已拓展至全国366个城市，月服务150万车辆台次。</p>
                </div> 

            </div>

             <div class="clear"></div>
        </div>
    </div>
    


</div>
</template>

<script>
export default {
  name: 'index',
  data(){
    return{
        
    }
  },
  methods:{
      clickFh(){
          this.$router.go(-1);
      }
  },
    mounted(){
        AOS.init({
            duration : 300,
            easing: 'ease-in-sine',  
            delay: 300
        })   
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.industry_banner img{width: 100%;}

.IndustryDetails_big{padding: 50px 0;}
.IndustryDetails_left{margin-right: 5%; float: left;}
.IndustryDetails_right{ width: 85%; float: left;}

.IndustryDetails_right_title{position: relative; width: 100%;}
.IndustryDetails_right_title h2{font-size: 22px; color: #333333; font-weight: bold;}
.IndustryDetails_right_title em{display: block; color: #666666; margin: 5px 0 5px 0; font-size: 14px;}
.IndustryDetails_right_title i{font-size: 14px; color: #999999;}

.page02{position: absolute; right: 0; top: 0;}
.page02 a{margin-left: 10px; padding: 10px 0;}
.page02 a i{font-size: 36px; font-weight: bold; color: #0072FF; transition: 0.5s all;}


.IndustryDetails_right_con{margin-top: 30px;}
.IndustryDetails_right_con p{margin-bottom: 20px; font-size: 16px; color: #333333; line-height: 28px;}

.industry_banner span img{display: none;}

@media(max-width:1200px){
    .IndustryDetails_big{padding: 30px 15px;}
}
@media(max-width:1000px){
    .IndustryDetails_left{ margin-bottom: 20px;}
    .IndustryDetails_right{ width: 100%;}
    .page02{top: -44px;}
    .page02 a i{font-size: 24px; }
    .IndustryDetails_right_con{margin-top: 20px;}
}
@media(max-width:800px){
    .IndustryDetails_right_title h2{font-size: 18px; line-height: 24px;}
    .IndustryDetails_right_title em{margin: 10px 0 5px 0; }
    .IndustryDetails_right_con p{font-size: 14px;}
}

@media(max-width:600px){
    .industry_banner img{display: none;}
    .industry_banner span img{display: block;}
}

@media(max-width:500px){
    .IndustryDetails_right_title h2{font-size: 16px; line-height: 24px;}
    .IndustryDetails_right_title em{margin: 5px 0; font-size: 12px; }
    .IndustryDetails_right_title i{font-size: 12px;}
    .page02 a i{font-size: 20px; }
    .page02{top: -40px;}
    .IndustryDetails_big{padding: 30px 15px 10px 15px;}

}

</style>
