<template>
<div class="index">


<!-- Banner -->

    <div id="demo" class="carousel slide" data-ride="carousel">
        <!-- 指示符 -->
        <ul class="carousel-indicators">
            <li data-target="#demo" data-slide-to="0" class="active"></li>
            <li data-target="#demo" data-slide-to="1"></li>
            <li data-target="#demo" data-slide-to="2"></li>
        </ul>

        <!-- 轮播图片 -->
        <div class="carousel-inner index_banner">
            <div class="carousel-item active carousel-bg1">
                <a href="javascript:;">
                    <em><img src="../assets/images/banner01.png" style="width: 100%;"></em>
                    <samp>
                        <em>全球优选 <span>高品质</span></em>
                        <em>汽配一站式 <span>采购平台</span></em>
                        <p>One stop purchase platform of global <br>
high quality auto parts</p>
                        <i>立即体验</i>
                    </samp>
                </a>
            </div>
            <div class="carousel-item carousel-bg2">
                <a href="javascript:;">
                    <em><img src="../assets/images/banner01.jpg" style="width: 100%;"></em>
                </a>
            </div>
            <div class="carousel-item carousel-bg3">
                <a href="javascript:;">
                    <em><img src="../assets/images/banner04.jpg" style="width: 100%;"></em>
                </a>
            </div>
        </div>
    </div>

    <div id="mobile" class="carousel slide" data-ride="carousel">
        <!-- 指示符 -->
        <ul class="carousel-indicators">
            <li data-target="#demo" data-slide-to="0" class="active"></li>
            <li data-target="#demo" data-slide-to="1"></li>
            <li data-target="#demo" data-slide-to="2"></li>
        </ul>

        <!-- 轮播图片 -->
        <div class="carousel-inner index_banner">
            <div class="carousel-item active carousel-bg1">
                <a href="javascript:;">
                    <img src="../assets/images/mb01.jpg" alt="">
                </a>
            </div>
            <div class="carousel-item carousel-bg2">
                <a href="javascript:;">
                    <img src="../assets/images/mb01.jpg" alt="">
                </a>
            </div>
            <div class="carousel-item carousel-bg3">
                <a href="javascript:;">
                    <img src="../assets/images/mb01.jpg" alt="">
                </a>
            </div>
        </div>
    </div>

 <!-- Banner End -->

    <div class="container">
        <div class="index_test" data-aos="fade-up">
            <p>作为一名供应链管理者,我心怀感激</p>
            <p>感谢所有在供应链领域做出杰出贡献的实践者</p>
            <p>感谢他们奋斗创新、创立典范、强企兴国</p>
        </div>

        <div class="index_icon">
            <div class="index_icon_ul" data-aos="fade-up">
                <ul>
                    <li>
                        <p><img src="../assets/images/icon01.png" alt=""></p>
                        <em>正品保障</em>
                        <i>品质护航 购物无忧</i>
                    </li>
                    <li>
                        <p><img src="../assets/images/icon02.png" alt=""></p>
                        <em>品类丰富</em>
                        <i>千万配件 一站采购</i>
                    </li>
                    <li>
                        <p><img src="../assets/images/icon03.png" alt=""></p>
                        <em>配送及时</em>
                        <i>急速物流  闪电送达</i>
                    </li>
                    <li>
                        <p><img src="../assets/images/icon04.png" alt=""></p>
                        <em>价格优惠</em>
                        <i>价格实在 放心购买</i>
                    </li>
                    <div class="clear"></div>
                </ul>
            </div>
        </div>
    </div>

    <div class="index_banner02" >
        <div class="index_banner02_img">
            <img src="../assets/images/index01.png" alt="">
            <span><img src="../assets/images/index08.png" alt=""></span>
        </div>
        <div class="index_banner02_wz" data-aos="fade-up">
            <em>全球优选集采优势</em>
            <p>利用全球的资源，在全世界范围内寻找优质原材料集中采购，有效降低产品成本，生产出质量过硬、价格合理的产品，为目标市场和特定的购买者提供优质的产品和服务。
开展集中化采购将特定的用户群体聚焦进行管理及服务，减少用户管理成本。</p>
        </div>
    </div>

    <div class="container">
        <div class="index_Card">
            <div class="index_Card_big" data-aos="fade-up">
                <div class="index_Card_wzLeft">
                    <em>全生态知识产权品牌</em>
                    <p>平台旗下所有汽车零部件产品品牌在商业中使用的标志、名称、图像，均经过公司商标注册，公司均拥有知识产权。公司享有所有品牌在经营活动中应用和使用的专有权利。</p>
                </div>
                <div class="index_Card_imgRight">
                    <img src="../assets/images/index02.png" alt="">
                </div>
                <div class="clear"></div>
            </div>

            <div class="index_Card_big" data-aos="fade-up">
                <div class="index_Card_imgLeft">
                    <img src="../assets/images/index07.png" alt="">
                </div>
                <div class="index_Card_wzRight">
                    <em>全国一体化仓配体系</em>
                    <p>公司旗下设立中央仓、分拨仓、省仓、市仓等仓储中心，仓配一体化将收货、仓储、拣选、包装、分拣、配送等功能集成起来，服务贯穿整个供应链的始终。</p>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>


    <div class="index_banner03">
        <div class="index_banner03_img">
            <img src="../assets/images/index03.png" alt="">
            <span><img src="../assets/images/index09.png" alt=""></span>
        </div>
        <div class="index_banner03_wz" data-aos="fade-up">
            <em>全站式强大物流体系</em>
            <p>海上十一昌是全球优选、高品质汽配一站式采购平台，拥有完善而又强大的物流体系。
通过刘五洲省际物流、周吴郑王省内干线物流、槽小强同城配送和即日达物流、
仓储及分发等，为客户提供一站式汽配采购物流解决方案。</p>
        </div>
    </div>
  <div class="container">
    <div class="index_brand">

      <div class="index_brand_title">
          <strong><em>Brand</em> recommendation</strong>
          <p>品牌推荐</p>
      </div>

      <div class="index_brand_ul" data-aos="fade-up">
        <ul>
          <li>
              <a href="#">
                <i><img src="../assets/images/index04.png" alt=""></i>
                <samp>
                    <em>七度系列 合资车系品牌</em>
                    <p>此品牌应用于合资车系，用于覆盖件</p>
                    <span>
                        了解更多
                        <strong><img src="../assets/images/icon05.png" alt=""></strong>
                        </span>
                </samp>
              </a>
              </li>
          <li>
              <a href="#">
                <i><img src="../assets/images/index05.png" alt=""></i>
                <samp>
                    <em>九天系列 国产车系品牌</em>
                    <p>此品牌应用于国产车系，用于覆盖件</p>
                    <span>
                        了解更多
                        <strong><img src="../assets/images/icon05.png" alt=""></strong>
                        </span>
                </samp>
              </a>
              </li>
            <li>
              <a href="#">
                <i><img src="../assets/images/index06.png" alt=""></i>
                <samp>
                    <em>轰达系列 合资车系品牌</em>
                    <p>此品牌应用于合资车系，用于发动机件</p>
                    <span>
                        了解更多
                        <strong><img src="../assets/images/icon05.png" alt=""></strong>
                        </span>
                </samp>
              </a>
            </li>
            <div class="clear"></div>
        </ul>
      </div>
    </div>
  </div>


</div>
</template>

<script>
export default {
  name: 'index',
  data(){
    return{

    }
  },
  mounted(){
      this.damoa()
  },
  methods:{
      damoa(){
        AOS.init({
            duration : 300,
            easing: 'ease-in-sine',
            delay: 300
        })
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.index_banner em{position: relative;}
.index_banner samp {position: absolute; top: 22%; left: 0; width: 100%; max-width: 1200px; right: 0; margin: 0px auto; display: block;}
.index_banner samp em{font-size: 48px; color: #FFFFFF; display: block;}
.index_banner samp em span{font-size: 48px; color: #FFC600; font-weight: bold;}
.index_banner p{display: block; margin-top: 10px; text-transform: uppercase; font-size: 18px; color: #FFFFFF; line-height: 28px; font-weight: normal;}
.index_banner i{padding: 10px 40px; font-size: 30px; display: inline-block; color: #FFC600; border: 2px solid #FFFFFF; border-radius: 50px; margin-top: 30px;}

.index_test{width: 100%; text-align: center; margin: 80px auto;}
.index_test p{font-size: 18px; color: #333333; margin: 10px 0;}

.index_icon{ width: 100%;}
.index_icon .index_icon_ul li{width: 25%; float: left;text-align: center; background: url(../assets/images/bg34.png) no-repeat center right;}
.index_icon .index_icon_ul li:last-of-type{border-right: 0;}
.index_icon .index_icon_ul li p img{width: 25%;}
.index_icon .index_icon_ul li em{ display: block; color: #333333; font-size: 16px; font-weight: bold; margin: 15px 0 10px 0;}
.index_icon .index_icon_ul li i{ font-size: 14px; color: #999999;}
.index_icon .index_icon_ul li:last-of-type{background: none;}

.index_banner02{width: 100%;  margin-top: 70px;position: relative;}
.index_banner02 .index_banner02_img img{ width: 100%;}
.index_banner02 .index_banner02_wz{position: absolute; width: 100%; margin: 0px auto; max-width: 1200px; padding: 0 15px;top: 50%;left: 50%;-webkit-transform:translate(-50%,-50%);}
.index_banner02 .index_banner02_wz em{font-size: 22px; font-weight: bold; display: block; color: #0072FF; margin-bottom: 10px; text-align: center;}
.index_banner02 .index_banner02_wz p{font-size: 16px; color: #FFFFFF; text-align: center;}
.index_banner02 .index_banner02_img span img{display: none;}

.index_Card{margin: 80px 0;}
.index_Card .index_Card_big{margin-bottom: 80px; box-shadow: 0px 0px 10px rgba(0, 0,0,0.3);}
.index_Card .index_Card_big .index_Card_wzLeft{width: 50%; float: left; box-sizing: border-box; padding: 0 20px 0 40px; padding-top: 7%}
.index_Card .index_Card_big .index_Card_wzLeft em{font-size: 24px;  color: #0072FF; display: block; margin-bottom: 15px;}
.index_Card .index_Card_big .index_Card_wzLeft p{font-size: 16px; color: #666666; line-height: 28px;}
.index_Card .index_Card_big .index_Card_imgRight{width: 50%; float: left; box-sizing: border-box; padding-left: 40px;}
.index_Card .index_Card_big .index_Card_imgRight img{width: 100%;}

.index_Card .index_Card_big .index_Card_imgLeft{width: 50%; float: left; box-sizing: border-box; padding-right: 40px;}
.index_Card .index_Card_big .index_Card_imgLeft img{width: 100%;}
.index_Card .index_Card_big .index_Card_wzRight{width: 50%; float: left; box-sizing: border-box; padding: 0 20px 0 40px; padding-top: 80px;}
.index_Card .index_Card_big .index_Card_wzRight em{font-size: 24px;  color: #0072FF; display: block; margin-bottom: 15px;}
.index_Card .index_Card_big .index_Card_wzRight p{font-size: 16px; color: #666666; line-height: 28px;}

.index_banner03{width: 100%;  margin-top: 70px;position: relative;}
.index_banner03 .index_banner03_img img{ width: 100%;}
.index_banner03 .index_banner03_wz{position: absolute; width: 100%; margin: 0px auto; max-width: 1200px; padding: 0 15px;top: 50%;left: 50%;-webkit-transform:translate(-50%,-50%);}
.index_banner03 .index_banner03_wz em{font-size: 22px; font-weight: bold; display: block; color: #FFC600; margin-bottom: 10px; text-align: left;}
.index_banner03 .index_banner03_wz p{font-size: 16px; color: #FFFFFF; text-align: left; width: 50%; line-height: 28px;}

.index_banner03 .index_banner03_img span img{display: none;}

.index_brand{padding: 50px 0;}

.index_brand_title{width: 100%;margin: 30px auto; text-align: center;}
.index_brand_title strong{font-size: 24px; color: #0072FF; text-transform:uppercase}
.index_brand_title strong em{font-weight: normal; color: #999999;}
.index_brand_title p{font-size: 18px; color: #0072FF;}

.index_brand .index_brand_ul{margin: 50px 0;}
.index_brand .index_brand_ul li{width: 31%; float: left; margin-right: 3.5%; box-shadow: 0 0 10px rgba(0, 0,0,0.3); overflow: hidden; }
.index_brand .index_brand_ul li:last-of-type{margin-right: 0;}
.index_brand .index_brand_ul li i{display: block; text-align: center;transition: 0.5s all;background: #FFFFFF;}
.index_brand .index_brand_ul li samp{display: block;width: 100%; padding:15px; background: #0072FF;}
.index_brand .index_brand_ul li samp em{font-size: 16px; font-weight: bold; color: #FFFFFF;}
.index_brand .index_brand_ul li samp p{font-size: 14px; color: #FFFFFF;}
.index_brand .index_brand_ul li samp span{display: inline-block; margin-top: 10px;  color: #333333; font-size: 12px; padding: 0px 10px; border-radius: 15px; background: #FFFFFF;}
.index_brand .index_brand_ul li samp span strong{padding-left: 2px;}
.index_brand .index_brand_ul li samp span strong img{zoom: 0.5;vertical-align: -6px; }

#mobile{display: none;}
#mobile img{width: 100%; display: block;}
#demo{display: block;}

.index_brand .index_brand_ul li:hover i{ transform: scale(1.05);}

@media(max-width:1500px){
    .index_banner samp em{font-size: 36px;}
    .index_banner samp em span{font-size: 36px;}
    .index_banner p{font-size: 16px;}
    .index_banner i{padding: 5px 30px; font-size: 24px; margin-top: 20px;}
}
@media(max-width:1300px){
    .index_banner samp{left: 5%;}
}

@media(max-width:1200px){
.index_test{margin: 60px 0;}
.index_test p{font-size: 16px; margin: 5px 0;}
.index_banner02 .index_banner02_wz em{font-size: 18px;}
.index_banner02 .index_banner02_wz p{font-size: 14px;}

.index_banner03 .index_banner03_wz em{font-size: 18px;}
.index_banner03 .index_banner03_wz p{font-size: 14px;}

.index_Card{padding: 0 15px;}

.index_banner02{margin-top: 50px;}
.index_Card{margin: 60px 0;}
.index_Card .index_Card_big{margin-bottom:60px}

.index_banner03{margin-top: 50px;}

.index_brand{padding: 20px 15px;}

.index_brand .index_brand_ul li i img{width: 100%;}

    .index_banner samp{top: 18%;}

}

@media(max-width:1000px){
.index_Card .index_Card_big .index_Card_wzLeft{padding-top: 5%;}
.index_Card .index_Card_big .index_Card_wzRight{padding-top: 5%;}
.index_Card .index_Card_big .index_Card_wzLeft em{font-size: 18px;}
.index_Card .index_Card_big .index_Card_wzLeft p{font-size: 14px;}

.index_Card .index_Card_big .index_Card_wzRight em{font-size: 18px;}
.index_Card .index_Card_big .index_Card_wzRight p{font-size: 14px;}

    .index_banner samp{top: 14%;}
    .index_banner samp em{font-size: 24px;}
    .index_banner samp em span{font-size: 24px;}
    .index_banner p{font-size: 14px;}
    .index_banner i{padding: 5px 20px; font-size: 18px; margin-top: 10px;}


}

@media(max-width:850px){
    .index_Card .index_Card_big .index_Card_wzLeft{width: 95%; float: none; text-align: center;  padding-left: 0; padding-right: 0; text-align: center; margin: 0px auto 20px auto;}
    .index_Card .index_Card_big .index_Card_imgRight{width: 100%; float: none; width: 95%; margin: 0px auto; padding:0; padding-bottom: 2%;}
    .index_Card .index_Card_big .index_Card_imgRight img{ width: 100%;}

    .index_Card .index_Card_big .index_Card_wzRight{width: 95%; float: none; text-align: center;  padding-left: 0; padding-right: 0; text-align: center; margin: 0px auto 0 auto; padding: 2% 0;}
    .index_Card .index_Card_big .index_Card_imgLeft{width: 100%; float: none; width: 95%; margin: 0px auto; padding:0; padding-top: 2%;}
    .index_Card .index_Card_big .index_Card_imgLeft img{ width: 100%;}

    .index_Card .index_Card_big .index_Card_wzLeft em{font-size: 16px;}
    .index_Card .index_Card_big .index_Card_wzRight em{font-size: 16px;}

    .index_brand .index_brand_ul li samp em{font-size: 14px; ;}
    .index_brand .index_brand_ul li samp p{font-size: 12px;}

    .index_brand_title strong{font-size: 18px;}
    .index_brand_title p{font-size: 14px;}
}

@media(max-width:750px){
    .index_banner samp em{font-size: 18px;}
    .index_banner samp em span{font-size: 18px;}
    .index_banner p{font-size: 14px;}
    .index_banner i{padding: 5px 15px; font-size: 16px; margin-top: 5px;}
}

@media(max-width:700px){
    .index_banner03 .index_banner03_wz em{font-size: 16px; font-weight: bold; display: block; color: #FFC600; margin-bottom: 10px; text-align: center;}
    .index_banner03 .index_banner03_wz p{font-size: 14px; color: #FFFFFF; text-align: center; width: 100%;}


    .index_brand .index_brand_ul{margin: 30px 0 20px 0;}
}

@media(max-width:600px){
    .index_test{margin: 30px 0;}
    .index_test p{font-size: 14px; margin: 0;}

    .index_icon .index_icon_ul li em{font-size: 14px; margin: 10px 0 0 0;}
    .index_icon .index_icon_ul li i{ font-size: 12px;}

    .index_banner02{margin-top: 30px;}
    .index_Card{margin: 40px 0;}
    .index_Card .index_Card_big{margin-bottom:30px}

    .index_banner02{margin-top: 30px;}

    .index_brand .index_brand_ul li{width: 31.5%; margin-right: 2.7%;}

    .index_brand_title{margin: 10px 0;}
    .index_brand_title strong{font-size: 16px;}

    .index_banner02 .index_banner02_img img{display: none;}
    .index_banner02 .index_banner02_img span img{display: block}

    .index_banner03 .index_banner03_img img{display: none;}
    .index_banner03 .index_banner03_img span img{display: block;}

    .index_banner03 .index_banner03_wz{-webkit-transform:translate(0%,0%); left: 0; top: 5%;}


    #mobile{display: block;}
    #demo{display: none;}

}
@media(max-width:500px){
    .index_test p{font-size: 12px;}

    .index_icon .index_icon_ul li{width: 50%; margin-bottom: 20px;}
    .index_icon .index_icon_ul li:nth-of-type(2n){background: none;}

    .index_banner02 .index_banner02_wz em{font-size: 14px; margin-bottom: 5px;}
    .index_banner02 .index_banner02_wz p{font-size: 12px;}

    .index_Card .index_Card_big .index_Card_imgRight{width: 92%; padding-bottom: 4%;}
    .index_Card .index_Card_big .index_Card_imgLeft{width: 92%; padding-top: 4%;}

    .index_Card .index_Card_big .index_Card_wzLeft em{font-size: 14px; margin-bottom: 5px;}
    .index_Card .index_Card_big .index_Card_wzLeft p{font-size: 12px; line-height: 24px;}

    .index_Card .index_Card_big .index_Card_wzRight em{font-size: 14px; margin-bottom: 5px;}
    .index_Card .index_Card_big .index_Card_wzRight p{font-size: 12px; line-height: 24px;}

    .index_banner03 .index_banner03_wz{padding: 0 10px;}
    .index_banner03 .index_banner03_wz em{font-size: 14px; margin-bottom: 0px;}
    .index_banner03 .index_banner03_wz p{font-size: 12px; line-height: 24px;}

    .index_brand .index_brand_ul li{width: 100%; margin: 0 0 20px 0;}
}

</style>
