import Vue from 'vue'
import VueRouter from 'vue-router'
import Nav from '../components/nav.vue'

import Index from '../views/index.vue'
import Brandmall from '../views/Brandmall.vue'
import Industry from '../views/IndustryNews.vue'
import IndustryDetails from '../views/IndustryDetails.vue'
import Aboutus from '../views/AboutUs.vue'
import JoinIn from '../views/JoinIn.vue'

Vue.use(VueRouter)

const routes = [
  // {path: '/',component: Nav},
  // {path: '/index',component: Index},
  // {path: '/brandmall',component: Brandmall},
  // {path: '/industry',component: Industry},
  // {path: '/industryDetails',component: IndustryDetails},
  // {path: '/about',component: Aboutus},
  // {path: '/joinIn',component: JoinIn}

  {path: '/', redirect: '/index'},
  {
    path: '/nav',
    component: Nav,
    redirect: '/index',
    children: [
      {path: '/index',component: Index ,meta:{title:'海上十一昌',keepAlive:true}},
      {path: '/brandmall',component: Brandmall => require(['../views/Brandmall.vue'],Brandmall),meta:{title:'品牌商城',keepAlive:true}},
      {path: '/industry',component: Industry,meta:{title:'行业动态',keepAlive:true}},
      {path: '/industryDetails',component: IndustryDetails,meta:{title:'详情页',keepAlive:true}},
      {path: '/about',component: Aboutus,meta:{title:'关于十一昌',keepAlive:true}},
      {path: '/joinIn',component: JoinIn,meta:{title:'加盟合作',keepAlive:true}}
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

export default router