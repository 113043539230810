<template>
<div class="publicStyle">
  <div class="nav nh_nav">

    <div class="container">
      <nav class="navbar navbar-default" role="navigation">
        <div class="container-fluid">
            <!--header section -->
            <div class="navbar-header">
                <button type="button" class="navbar-toggle nav_btn" data-toggle="collapse" data-target=".navbar-ex1-collapse" @click="clickBtn()">
                    <span aria-hidden="true">
                      <em v-if="testBtn==false"><img src="../assets/images/daohanglan.png" alt=""></em>
                      <em v-else><img src="../assets/images/guanbi.png" alt=""></em>
                    </span>
                </button>
                <!-- <a class="navbar-brand" href="#"><img src="../assets/images/logo.png" alt=""></a>    -->
                <a href="javascript:;" @click="btnLogo()"><img src="../assets/images/logo.png" alt=""></a>

            </div>
            <!-- menu section -->
            <div class="collapse navbar-collapse navbar-ex1-collapse header_nav" id="header_nav">
                <ul class="nav navbar-nav navbar-right">
                  <li :class="{on:index==current}" v-for="(item,index) in navLink" :key="index" @click="clickNav($event,index)"><router-link :to="item.LinkUrl" >{{item.name}}</router-link></li>
                </ul>
            </div>
        </div>
      </nav>
    </div>
  </div>
  <div class="nav_none"></div>
      <router-view></router-view>


    <div class="bottom">
    <div class="container">
      <div class="bottom_top">
        <img src="../assets/images/ewm.png" alt="">
        <p>十一昌公众号</p>
      </div>
      <div class="bottom_center">
        <img src="../assets/images/bottom_title.png">
      </div>
      <div class="bottom_from">
        <table>
          <tr>
            <td><p>加盟热线：17717553824</p></td>
            <td rowspan="2"><textarea placeholder="我要留言："></textarea></td>
          </tr>
          <tr>
            <td><p>企业邮箱：ysshssyc@163.com</p></td>
          </tr>

          <tr>
            <td><p>总部地址：上海市闵行区闵北路88弄1-30号104幢1层A区</p></td>
            <td><input type="submit" class="submit" value="提交留言"></td>
          </tr>

        </table>
      </div>

      <div class="bottom_keep">
        <p>版权所有：十一昌（上海）供应链管理有限公司  <a href="#">备案号：沪ICP备2021009029号-1</a></p>
      </div>
    </div>
  </div>

    <div class="index_up" @click="backTop()" v-if="btnFlag">
        <a href="javascript:;"><img src="../assets/images/up.png" alt=""></a>
    </div>
</div>
</template>

<script>
export default {
  name: 'publicStyle',
  data(){
    return{
      testBtn:false,
      current:0,
      scrollTop:0,
      btnFlag:false,
      centerWidth: document.documentElement.clientWidth,
      navLink:[
        {id:1,name:"首页",LinkUrl:"index"},
        {id:2,name:"品牌商城",LinkUrl:"brandmall"},
        {id:3,name:"行业动态",LinkUrl:"industry"},
        {id:4,name:"关于十一昌",LinkUrl:"about"},
        {id:5,name:"加盟合作",LinkUrl:"joinIn"}
      ]
    }
  },

  created(){
    this.current=window.sessionStorage.getItem('index');
    console.log(this.centerWidth)
  },
  methods:{
    clickBtn(){
      this.testBtn =!this.testBtn;
    },

    backTop(){
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    btnLogo(){
      this.$router.push("/index")
      this.current=0
      window.sessionStorage.setItem('index',0);
    },

    scrollToTop () {
      const that = this
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 800) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    },
    clickNav(e,index){
      window.sessionStorage.setItem('index',index);
      this.current=index;
      this.testBtn =!this.testBtn
      e.currentTarget.parentElement.parentElement.classList.remove("show")
    },
    aosDt(){

    }
  },
    mounted() {
      window.addEventListener('scroll', this.scrollToTop);
      this.aosDt();

    window.onresize = () => {
      this.centerWidth = document.documentElement.clientWidth;
      if(this.centerWidth<=825){
        this.testBtn = false
        let d = document.getElementById("header_nav").classList.remove("show")
      }
    };

    },
    beforUpdate(){

    },
    destroyed () {
      window.removeEventListener('scroll', this.scrollToTop)
    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nh_nav{padding: 10px 0;}
.nh_nav .container-fluid{padding: 0; position: relative; display: block; width: 100%;}
.collapse:not(.show){display: block;}
.navbar-collapse{flex-basis:auto; flex-grow:inherit}
.navbar{padding: 0;}
.navbar-nav{flex-direction:inherit}
.nav_btn{display: none;}
.header_nav li{ display: inline-block; margin: 0 5px 0 30px;}
.header_nav li a{font-size: 18px; color: #666666; padding-bottom: 5px;}
.header_nav li:hover a{ color: #0072FF; border-bottom: 3px solid #0072FF;}
.header_nav .on a{ color: #0072FF; border-bottom: 3px solid #0072FF;}
.header_nav li .router-link-active{ color: #0072FF; border-bottom: 3px solid #0072FF;}

.nh_nav .navbar-header button{background: none; border: none; float: right; margin-top: 5px;}
.nh_nav .navbar-header button img{zoom: 0.5;}

.header_nav .navbar-nav{position: absolute; right: 0; top:5px;}

.bottom{background: url(../assets/images/bottom.png) no-repeat center top; background-size: cover; padding-bottom: 60px; position: relative;}
.bottom .bottom_top{ padding-bottom: 0px; margin: 0px auto; width: 100%; text-align: center;  position: relative; top: -85px; margin-top: 85px;}
.bottom .bottom_top p{font-size: 18px; color: #FFFFFF; margin-top: 10px;}

.bottom .bottom_center{width: 100%; text-align: center; padding-bottom: 40px;}
.bottom .bottom_from table{ width: 100%;}
.bottom .bottom_from table tr td {padding-bottom: 10px; width: 50%;}
.bottom .bottom_from table tr td:nth-child(2){ margin-right: 0;}
.bottom .bottom_from table tr td p{width: 98%; padding-left: 15px; display: block; box-sizing:border-box; line-height: 38px; font-size: 14px; color: #FFFFFF; height: 38px; border-radius: 5px; border: 1px solid #FFFFFF;}
.bottom .bottom_from table tr td p:nth-of-type(0){margin-bottom: 10px;}
.bottom .bottom_from table tr td textarea{width: 100%; padding-bottom: 10px; padding-left: 15px; display: block; box-sizing:border-box; line-height: 38px; font-size: 14px; color: #FFFFFF; height: 100%; border-radius: 5px; border: 1px solid #FFFFFF; background: transparent;}
.bottom .bottom_from table tr td .submit{width: 100%; display: block; box-sizing:border-box; line-height: 38px; font-size: 14px; color: #FFFFFF; background: transparent; height: 38px; border-radius: 5px; border: 1px solid #FFFFFF; }

.bottom .bottom_keep{margin-top: 30px; padding-bottom: 30px;}
.bottom .bottom_keep p{font-size: 14px; color: #FFFFFF; text-align: center;}
.bottom .bottom_keep p a{font-size: 14px; color: #FFFFFF; padding-left: 10px;}

.index_up{position: fixed; right: 0%; top:80%; -webkit-transform:translate(0,-80%); z-index: 99; zoom: 0.7;}

.nav_none{height: 60px; width: 100%;}

.nh_nav{ position: fixed; top: 0; z-index: 99; left: 0; width: 100%; background: #FFFFFF;}

@media (max-width:1200px){
  .bottom{padding: 0 15px;}
  .nh_nav{padding: 10px 15px;}
}

@media (max-width:900px){
.nh_nav .navbar-header{padding: 10px; border-bottom: 1px solid #999999;}
.header_nav li{ margin: 0 10px 0 20px;}
.header_nav li a{font-size: 16px;}

.bottom .bottom_top{top: -80px; margin-top: 80px;}
.bottom .bottom_top img{zoom: 0.9;}
.bottom .bottom_top p{font-size: 16px;}

.header_nav .navbar-nav{top: 15px;}

.nh_nav {padding: 0;}
}

@media (max-width:850px){
  .bottom .bottom_from table tr{width: 100%;}
  .bottom .bottom_from table tr td {display: block; width: 100%;}
  .bottom .bottom_from table tr td p{width: 100%;}
}

@media (max-width:802px){
  .nav_btn{display: block;}

  /* .collapse{display: inherit;} */
  .collapse:not(.show){display: none;}
  .header_nav{position: fixed; width: 100%; left: 0; z-index: 5;}
  .header_nav .navbar-nav{width: 100%; padding: 10px; top: 0px; background: #FFFFFF; }
  .header_nav .navbar-nav li{ display: block; width: 100%; margin: 5px 0; }
  .header_nav .navbar-nav li a{ color: #999999; border-bottom: none; display: block; font-size: 16px;padding: 0;}

  .navbar-brand img{width: 90%;}
  .header_nav img{width: 90%;}

  .bottom .bottom_top img{zoom: 0.8;}


  .nav_none{display: block;}
}

@media (max-width:700px){
.bottom .bottom_top {top: -50px; margin-top:50px}
.bottom .bottom_top img{zoom: 0.6;}
.bottom .bottom_top p{font-size: 14px;}
.bottom_center img {zoom: 0.8;}

.bottom .bottom_center{padding-bottom: 20px;}
.nh_nav{padding: 0;}

.index_up{display: none;}

}

@media (max-width:576px){
  .header_nav .navbar-nav li a{font-size: 14px;}
  .nh_nav .navbar-header button img{zoom: 0.4;}

  .bottom_center img {width: 80%; zoom: 1;}
}

@media (max-width:400px){
.bottom .bottom_from table tr td p{font-size: 12px;}
}

</style>
