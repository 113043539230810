<template>
<div class="Brandmall">


<!-- Banner -->

    <div id="demo" class="carousel slide" data-ride="carousel">
        <!-- 指示符 -->
        <ul class="carousel-indicators">
            <li data-target="#demo" data-slide-to="0" class="active"></li>
            <li data-target="#demo" data-slide-to="1"></li>
            <li data-target="#demo" data-slide-to="2"></li>
        </ul>

        <!-- 轮播图片 -->
        <div class="carousel-inner">
            <div class="carousel-item active carousel-bg1">
                <img src="../assets/images/bg17.png" style="width: 100%;">
            </div>
            <div class="carousel-item carousel-bg2">
                <img src="../assets/images/banner02.jpg" style="width: 100%;">
            </div>
            <div class="carousel-item carousel-bg3">
                <img src="../assets/images/banner03.jpg" style="width: 100%;">
            </div>
        </div>
    </div>

    <div id="mobile" class="carousel slide" data-ride="carousel">
        <!-- 指示符 -->
        <ul class="carousel-indicators">
            <li data-target="#demo" data-slide-to="0" class="active"></li>
            <li data-target="#demo" data-slide-to="1"></li>
            <li data-target="#demo" data-slide-to="2"></li>
        </ul>

        <!-- 轮播图片 -->
        <div class="carousel-inner">
            <div class="carousel-item active carousel-bg1">
                <img src="../assets/images/index11.png" style="width: 100%;">
            </div>
            <div class="carousel-item carousel-bg2">
                <img src="../assets/images/index11.png" style="width: 100%;">
            </div>
            <div class="carousel-item carousel-bg3">
                <img src="../assets/images/index11.png" style="width: 100%;">
            </div>
        </div>
    </div>

 <!-- Banner End -->
    <div class="container">
        <el-tabs type="card" class="eltabs_Brand" data-aos="fade-up">
            <el-tab-pane label="覆盖件">
                <div class="ele_tabsUl">
                    <ul>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img23.jpg" alt=""></em>
                                <i><img src="../assets/images/img24.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img25.jpg" alt=""></em>
                                <i><img src="../assets/images/img26.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img27.jpg" alt=""></em>
                                <i><img src="../assets/images/img28.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img31.jpg" alt=""></em>
                                <i><img src="../assets/images/img32.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img33.jpg" alt=""></em>
                                <i><img src="../assets/images/img34.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img35.jpg" alt=""></em>
                                <i><img src="../assets/images/img36.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img37.jpg" alt=""></em>
                                <i><img src="../assets/images/img38.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img39.jpg" alt=""></em>
                                <i><img src="../assets/images/img40.jpg" alt=""></i>
                            </a>
                        </li>
                        <div class="clear"></div>
                    </ul>
                </div>
            </el-tab-pane>
            <el-tab-pane label="底盘件">
                <div class="ele_tabsUl">
                    <ul>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img21.jpg" alt=""></em>
                                <i><img src="../assets/images/img22.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img29.jpg" alt=""></em>
                                <i><img src="../assets/images/img30.jpg" alt=""></i>
                            </a>
                        </li>
                      <li>
                        <a href="#">
                          <em><img src="../assets/images/img61.jpg" alt=""></em>
                          <i><img src="../assets/images/img62.jpg" alt=""></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <em><img src="../assets/images/img63.jpg" alt=""></em>
                          <i><img src="../assets/images/img64.jpg" alt=""></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <em><img src="../assets/images/img65.jpg" alt=""></em>
                          <i><img src="../assets/images/img66.jpg" alt=""></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <em><img src="../assets/images/img67.jpg" alt=""></em>
                          <i><img src="../assets/images/img68.jpg" alt=""></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <em><img src="../assets/images/img69.jpg" alt=""></em>
                          <i><img src="../assets/images/img70.jpg" alt=""></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <em><img src="../assets/images/img71.jpg" alt=""></em>
                          <i><img src="../assets/images/img72.jpg" alt=""></i>
                        </a>
                      </li>
                        <div class="clear"></div>
                    </ul>
                </div>
            </el-tab-pane>
            <el-tab-pane label="易损件">
                <div class="ele_tabsUl">
                  <ul>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img73.jpg" alt=""></em>
                        <i><img src="../assets/images/img74.jpg" alt=""></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img75.jpg" alt=""></em>
                        <i><img src="../assets/images/img76.jpg" alt=""></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img77.jpg" alt=""></em>
                        <i><img src="../assets/images/img78.jpg" alt=""></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img79.jpg" alt=""></em>
                        <i><img src="../assets/images/img80.jpg" alt=""></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img81.jpg" alt=""></em>
                        <i><img src="../assets/images/img82.jpg" alt=""></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img83.jpg" alt=""></em>
                        <i><img src="../assets/images/img84.jpg" alt=""></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img85.jpg" alt=""></em>
                        <i><img src="../assets/images/img86.jpg" alt=""></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img87.jpg" alt=""></em>
                        <i><img src="../assets/images/img88.jpg" alt=""></i>
                      </a>
                    </li>

                    <div class="clear"></div>
                  </ul>
                </div>
            </el-tab-pane>
            <el-tab-pane label="空调件">
                <div class="ele_tabsUl">
                    <ul>
                      <li>
                        <a href="#">
                          <em><img src="../assets/images/img89.jpg" alt=""></em>
                          <i><img src="../assets/images/img90.jpg" alt=""></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <em><img src="../assets/images/img91.jpg" alt=""></em>
                          <i><img src="../assets/images/img92.jpg" alt=""></i>
                        </a>
                      </li>

                      <div class="clear"></div>
                    </ul>
                </div>
            </el-tab-pane>
            <el-tab-pane label="电器件">
                <div class="ele_tabsUl">
                    <ul>
                      <li>
                        <a href="#">
                          <em><img src="../assets/images/img101.jpg" alt=""></em>
                          <i><img src="../assets/images/img102.jpg" alt=""></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <em><img src="../assets/images/img103.jpg" alt=""></em>
                          <i><img src="../assets/images/img104.jpg" alt=""></i>
                        </a>
                      </li>
                      <div class="clear"></div>
                    </ul>
                </div>
            </el-tab-pane>
            <el-tab-pane label="专业工具">
                <div class="ele_tabsUl">
                  <ul>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img97.jpg" alt=""></em>
                        <i><img src="../assets/images/img98.jpg" alt=""></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img99.jpg" alt=""></em>
                        <i><img src="../assets/images/img100.jpg" alt=""></i>
                      </a>
                    </li>
                    <div class="clear"></div>
                  </ul>
                </div>
            </el-tab-pane>
            <el-tab-pane label="变速箱件">
                <div class="ele_tabsUl">
                    <ul>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img41.jpg" alt=""></em>
                                <i><img src="../assets/images/img42.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img43.jpg" alt=""></em>
                                <i><img src="../assets/images/img44.jpg" alt=""></i>
                            </a>
                        </li>
                         <div class="clear"></div>
                    </ul>
                </div>
            </el-tab-pane>
            <el-tab-pane label="发动机件">
                <div class="ele_tabsUl">
                    <ul>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img45.jpg" alt=""></em>
                                <i><img src="../assets/images/img46.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img47.jpg" alt=""></em>
                                <i><img src="../assets/images/img48.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img49.jpg" alt=""></em>
                                <i><img src="../assets/images/img50.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img51.jpg" alt=""></em>
                                <i><img src="../assets/images/img52.jpg" alt=""></i>
                            </a>
                        </li>
                         <div class="clear"></div>
                    </ul>
                </div>
            </el-tab-pane>
            <el-tab-pane label="发动机附件">
                <div class="ele_tabsUl">
                    <ul>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img53.jpg" alt=""></em>
                                <i><img src="../assets/images/img54.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img55.jpg" alt=""></em>
                                <i><img src="../assets/images/img56.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img57.jpg" alt=""></em>
                                <i><img src="../assets/images/img58.jpg" alt=""></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <em><img src="../assets/images/img59.jpg" alt=""></em>
                                <i><img src="../assets/images/img60.jpg" alt=""></i>
                            </a>
                        </li>
                         <div class="clear"></div>
                    </ul>
                </div>
            </el-tab-pane>
            <el-tab-pane label="车身装饰件">
                <div class="ele_tabsUl">
                  <ul>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img93.jpg" alt=""></em>
                        <i><img src="../assets/images/img94.jpg" alt=""></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <em><img src="../assets/images/img95.jpg" alt=""></em>
                        <i><img src="../assets/images/img96.jpg" alt=""></i>
                      </a>
                    </li>
                    <div class="clear"></div>
                  </ul>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>


    <div class="Brandmall_banner01">
        <div class="Brandmall_banner01_img">
            <img src="../assets/images/bg01.png" alt="">
            <span><img src="../assets/images/index10.png" alt=""></span>
        </div>
        <div class="Brandmall_banner01_wz" data-aos="fade-up">
            <em>对产品的挑剔，长期坚守，已成信仰</em>
            <p>IT HAS BECOME A BELIEF TO BE PICKY ABOUT CHOICE AND STICK TO IT FOR A LONG TIME</p>
        </div>
    </div>


</div>
</template>

<script>
export default {
  name: 'index',
  data(){
    return{
        tabPosition: 'left',
        currentTop:0
    }
  },
  careted(){
  },
  methods:{
    damoa(){
        AOS.init({
            duration : 300,
            easing: 'ease-in-sine',
            delay: 300
        })
    }
  },
   mounted(){
      this.damoa()
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ele_tabsUl{box-shadow: 0 0 10px rgba(0,0,0,0.1);}
.ele_tabsUl ul li{width: 25%; float: left; transition: 0.5s all; overflow: hidden; border-bottom: 1px solid rgba(112,112,112,0.1); border-right: 1px solid rgba(112,112,112,0.1);}
/* .ele_tabsUl ul li:hover img{ transform: scale(1.05); transition: 0.5s all; box-shadow: 0 0 10px rgba(0,0,0,0.1);} */

.ele_tabsUl li{zoom: 0.8; background: #FFFFFF;}
.ele_tabsUl li img{width: 100%; display: block; }
.ele_tabsUl li i{display: none;}


.ele_tabsUl ul li:hover{  box-shadow: 0 0 10px rgba(0,0,0,0.1); transform: scale(1.03); }
.ele_tabsUl ul li:hover em{display: none;}
.ele_tabsUl ul li:hover i{display: block;}

.Brandmall_banner01{width: 100%; padding: 50px 0 80px 0;position: relative;}
.Brandmall_banner01 .Brandmall_banner01_img img{ width: 100%;}
.Brandmall_banner01 .Brandmall_banner01_wz{position: absolute; width: 100%; margin: 0px auto; max-width: 1200px; padding: 0 15px;top: 50%;left: 50%;-webkit-transform:translate(-50%,-50%);}
.Brandmall_banner01 .Brandmall_banner01_wz em{font-size: 22px; font-weight: bold; display: block; color: #FFFFFF; margin-bottom: 10px; text-align: center;}
.Brandmall_banner01 .Brandmall_banner01_wz p{font-size: 16px; color: #FFFFFF; text-align: center;}


.Brandmall_banner01 .Brandmall_banner01_img span img{display: none;}

#mobile{display: none;}
#mobile img{width: 100%; display: block;}
#demo{display: block;}

@media(max-width:1200px){
    .eltabs_Brand{padding: 0 15px;}
    .Brandmall_banner01{padding: 30px 0 50px 0;}

    .Brandmall_banner01 .Brandmall_banner01_wz em{font-size: 18px;}
    .Brandmall_banner01 .Brandmall_banner01_wz p{font-size: 14px;}
}
@media(max-width:700px){
    .Brandmall_banner01 .Brandmall_banner01_wz em{font-size: 16px;}
    .Brandmall_banner01{margin: 20px 0 ;}

    .Brandmall_banner01{padding: 0px 0 30px 0;}


}
@media(max-width:600px){
    .ele_tabsUl ul li{width: 50%;}

    .ele_tabsUl ul li em{display: none;}
    .ele_tabsUl ul li i{display: block;}

    .Brandmall_banner01 .Brandmall_banner01_img img{display: none;}
    .Brandmall_banner01 .Brandmall_banner01_img span img{display: block;}


    #mobile{display: block;}
    #demo{display: none;}
}

@media(max-width:500px){
    .Brandmall_banner01 .Brandmall_banner01_wz em{font-size: 14px; margin-bottom: 0px;}
    .Brandmall_banner01 .Brandmall_banner01_wz p{font-size: 12px;}
}

</style>
