<template>
<div class="Brandmall">


<!-- Banner -->

    <div class="industry_banner">
        <img src="../assets/images/bg07.png" alt="">
        <span><img src="../assets/images/index14.jpg" alt=""></span>
    </div>
   
 <!-- Banner End -->
    <div class="container">
        <div class="About_introduce_big">
            <div class="index_brand_title">
                <strong><em>About</em> shiyichang</strong>
                <p>十一昌简介</p>
            </div>

            <div class="about_introduce" data-aos="fade-up">
                <p>作为致力为改变行业乱象，提升行业全球竞争力，为行业导入秩序，重塑行业形象，让行业内成员回归本位，共享共造汽车后市场互竞互生生态是公司执业方向和具体目标。作为全球汽车后市场中的一员，及公司手术方案之一，十一昌集采平台致力于为生产者联盟和消费者联盟建立高效互信机制。为尊敬的商业会员提供值得信赖的高品质商品和服务。</p>
            </div>
        </div>
    </div>
    
    <div class="About_corporate_bg">
        <div class="container">
            <div class="About_corporate">
                <div class="index_brand_title">
                    <strong><em>corporate</em> ulture</strong>
                    <p>企业文化</p>
                </div>

                <div class="About_corporate_ul" >
                    <ul>
                        <li class="on" data-aos="fade-up">
                            <a href="#">
                                <p><img src="../assets/images/bg23.png" alt=""></p>
                                <samp>
                                    <em>企业宗旨</em>
                                    <span></span>
                                    <i>认真迅速信守承诺 简单高效可以信赖</i>
                                </samp>
                            </a>
                        </li>
                        <li data-aos="fade-up">
                            <a href="#">
                                <p><img src="../assets/images/bg08.png" alt=""></p>
                                <samp>
                                    <em>质量理念</em>
                                    <span></span>
                                    <i>以品质赢得尊重 靠奋斗成就未来</i>
                                </samp>
                            </a>
                        </li>
                        <li data-aos="fade-up">
                            <a href="#">
                                <p><img src="../assets/images/bg24.png" alt=""></p>
                                <samp>
                                    <em>人才理念</em>
                                    <span></span>
                                    <i>成年 同频 共情 奋斗与支撑</i>
                                </samp>
                            </a>
                        </li>
                        <li data-aos="fade-up">
                            <a href="#">
                                <p><img src="../assets/images/bg25.png" alt=""></p>
                                <samp>
                                    <em>企业宗旨</em>
                                    <span></span>
                                    <i>让十一昌供应链管理成为中国</i>
                                    <i>汽车工业腾飞强有力的支撑者</i>
                                </samp>
                            </a>
                        </li>
                        <div class="clear"></div>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="About_honor">
            <div class="index_brand_title">
                <strong><em>Honor</em> gained</strong>
                <p>荣誉获得</p>
            </div>

            <div class="About_honor_ul01">
                <ul>
                    <li data-aos="fade-up">
                        <em><img src="../assets/images/bg09.png" alt=""></em>
                        <p>企业信用等级</p>
                    </li>
                    <li data-aos="fade-up">
                        <em><img src="../assets/images/bg26.png" alt=""></em>
                        <p>重合同守信用</p>
                    </li>
                    <li data-aos="fade-up">
                        <em><img src="../assets/images/bg27.png" alt=""></em>
                        <p>质量服务诚信企业</p>
                    </li>
                    <li data-aos="fade-up">
                        <em><img src="../assets/images/bg28.png" alt=""></em>
                        <p>企业资信等级</p>
                    </li>
                    <li data-aos="fade-up">
                        <em><img src="../assets/images/bg29.png" alt=""></em>
                        <p>立信单位</p>
                    </li>
                    <li data-aos="fade-up">
                        <em><img src="../assets/images/bg30.png" alt=""></em>
                        <p>诚信经营示范单位</p>
                    </li>
                    <li class="on" data-aos="fade-up">
                        <em><img src="../assets/images/bg10.png" alt=""></em>
                        <p>企业信用等级</p>
                    </li>
                    <li data-aos="fade-up">
                        <em><img src="../assets/images/bg31.png" alt=""></em>
                        <p>诚信供应商</p>
                    </li>
                    <li data-aos="fade-up">
                        <em><img src="../assets/images/bg11.png" alt=""></em>
                        <p>企业信用等级</p>
                    </li>
                    <div class="clear"></div>
                </ul>
            </div>
        </div>
    </div>


</div>
</template>

<script>
export default {
  name: 'index',
  data(){
    return{
        
    }
  },
  mounted(){
      this.damoa()
  },
  methods:{
      damoa(){
        AOS.init({
            duration : 300,
            easing: 'ease-in-sine',  
            delay: 300
        })   
      }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.industry_banner img{width: 100%;}

.About_introduce_big{padding: 60px 0px;}

.index_brand_title{width: 100%;margin: 30px auto; text-align: center;}
.index_brand_title strong{font-size: 24px; color: #0072FF; text-transform:uppercase}
.index_brand_title strong em{font-weight: normal; color: #999999;}
.index_brand_title p{font-size: 18px; color: #0072FF;}

.about_introduce p{ font-size: 16px; color: #333333; text-indent: 2em; line-height: 28px;}

.About_corporate_bg{background: #F8F8F8; padding: 60px 0;}
.About_corporate_ul{margin-top: 20px;}
.About_corporate_ul li{width: 25%; float: left; position: relative; overflow: hidden; }
.About_corporate_ul li p img{width: 100%;
    transform: scale(1);
    transition: 0.3s all;
}
.About_corporate_ul li samp{width: 100%; text-align: center; position: absolute; left: 0; top: 0; background: rgba(0,0,0,0.3); height: 100%;
    transition: 0.3s all;
}
.About_corporate_ul li samp em{font-size: 20px; font-weight: bold; color: #FFFFFF; display: inline-block; display: inline-block; margin: 70px auto 0 auto; text-align: center; padding-bottom: 3px;
border: 1px solid rgba(255,255,255,0);
transition: 0.5s all;
}
.About_corporate_ul li samp span{width: 0; height: 2px; background: #FFFFFF; transition: 0.5s all; display: block; text-align: left;
 margin-left: 36%;
}

.About_corporate_ul li samp i{font-size: 16px; color: #FFFFFF; display: block;}
.About_corporate_ul li samp i:first-of-type{padding-top: 10px;}
.About_corporate_ul li:hover samp span{width: 82px;}

.About_corporate_ul li:hover samp em:after{opacity: 1; width: 50px;}

.About_corporate_ul li:hover p img{transform: scale(1.05);}
.About_corporate_ul li:hover samp{background: rgba(0,114,255,0.8); }

.About_honor{ padding: 60px 0;}
.About_honor_ul01{margin: 20px 0;}
.About_honor_ul01 ul li{float: left; width: 19%; height: 316px; padding-bottom: 30px; box-sizing: content-box; margin-right: 1%; margin-bottom: 20px;
display: flex; flex-direction: column; justify-content: center; position: relative;
}
.About_honor_ul01 ul li:last-of-type{margin-right: 0;}
.About_honor_ul01 ul li p{ position: absolute; bottom: 0px; margin: 0px auto; width: 100%; text-align: center; font-size: 16px; color: #333333; margin: 5px 0;}
.About_honor_ul01 ul .on{width: 39%;}

.industry_banner span img{display: none;}


@media(max-width:1200px){
 .About_introduce_big{padding: 40px 15px;}
 .About_corporate{padding: 0 15px;}

 .About_honor_ul01{padding: 0 15px;}

 .About_honor_ul01 ul li{height: 300px;}
 .About_honor_ul01 ul li img{ zoom: 0.9;}
}
@media(max-width:1070px){
 .About_honor_ul01 ul li{height: 280px; margin-bottom: 10px; padding-bottom: 20px;}
 .About_honor_ul01 ul li img{ zoom: 0.8;}

 .About_corporate_ul li samp em{ margin-top: 50px; font-size: 16px;}
 .About_corporate_ul li samp i{font-size: 14px;}

 
}

@media(max-width:1000px){
    .About_corporate_ul li samp{padding: 0 5px;}
    .About_corporate_ul li samp em{font-size: 16px; margin-bottom: 5px;}
    .About_corporate_ul li samp i{font-size: 14px;}

    .About_honor_ul01 ul li p{font-size: 14px; margin: 0;}

    .About_corporate_bg{padding: 20px 0 40px 0;}
    .About_honor{padding: 40px 0;}

}
@media(max-width:950px){
 .About_honor_ul01 ul li{height: 250px;}
 .About_honor_ul01 ul li img{ zoom: 0.75;}
}
@media(max-width:900px){
 .About_honor_ul01 ul li{height: 230px;}
 .About_honor_ul01 ul li img{ zoom: 0.7;}
}
@media(max-width:850px){
 .About_honor_ul01 ul li{height: 210px;}
 .About_honor_ul01 ul li img{ zoom: 0.65;}

 .About_corporate_ul li samp span{display: none;}
 .About_corporate_ul li samp i{padding-top: 0;}
}


@media(max-width:800px){
    .index_brand_title strong{font-size: 18px;}
    .index_brand_title p{font-size: 14px;}
    .about_introduce p{font-size: 14px; line-height: 24px;}

    .About_corporate_ul li samp{transform: scale(1);display: flex; flex-direction: column; justify-content: center;}
    .About_corporate_ul li samp em{font-size: 14px; margin-bottom: 0px; margin-top: 0px;}
    .About_corporate_ul li samp i{font-size: 12px;}

    .About_honor_ul01 ul li{height: 200px;}
    .About_honor_ul01 ul li img{ zoom: 0.6;}
}
@media(max-width:750px){
    .About_honor_ul01 ul li{height: 190px;}
    .About_honor_ul01 ul li img{ zoom: 0.55;}
}
@media(max-width:700px){
    .About_honor_ul01 ul li{height: 180px;}
    .About_honor_ul01 ul li img{ zoom: 0.5;}
}

@media(max-width:600px){
    .About_introduce_big{padding: 20px 15px;}
    .index_brand_title{margin: 10px 0;}
    .index_brand_title strong{font-size: 16px;}

    .About_corporate_ul li{width: 49%; margin-right: 2%; margin-bottom: 2%;}
    .About_corporate_ul li:nth-of-type(2n){margin-right: 0;}

    .About_honor_ul01 ul .on{width: 100%;}
    .About_honor_ul01 ul li{width: 49%; height: 100%; padding-bottom: 25px;}
    .About_honor_ul01 ul li img{zoom: 1; width: 100%;}

    .About_honor{padding: 20px 0;}

    .industry_banner img{display: none;}
    .industry_banner span img{display: block;}
}

</style>
