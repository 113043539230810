<template>
<div class="Brandmall">


<!-- Banner -->

    <div class="industry_banner">
        <img src="../assets/images/bg02.png" alt="">
        <span><img src="../assets/images/index13.jpg" alt=""></span>
    </div>
   
 <!-- Banner End -->
    <div class="container">
        <div class="industry_big">
            <div class="industry_ul" data-aos="fade-up">
                <li>
                    <router-link to="/industryDetails" href="#">
                        <p><img src="../assets/images/bg03.png" alt=""></p>
                        <em>开思汽配完成3500万美元C+轮融资</em>
                        <p>本轮融资将继续用于技术研发、平台基础设施建设、市场业务拓展等方面。<span>2020年06月22日</span></p>
                    </router-link>
                </li>
                <li>
                    <router-link to="/industryDetails" href="#">
                        <p><img src="../assets/images/bg18.png" alt=""></p>
                        <em>万亿级汽车后市场急需联动的数据</em>
                        <p>零零汽创始人兼CEO佘邵镔认为，我国的汽车后市场整体不太成熟。<span>2020年11月29日</span></p>
                    </router-link>
                </li>
                <li>
                    <router-link to="/industryDetails" href="#">
                        <p><img src="../assets/images/bg19.png" alt=""></p>
                        <em>阿里京东滴滴激战汽车后市场</em>
                        <p>从行业发展来看，汽车后市场的竞争格局愈发明显，未来还会走向多层次、全方位的竞争。<span>2019年01月31日</span></p>
                    </router-link>
                </li>
                <li>
                    <router-link to="/industryDetails" href="#">
                        <p><img src="../assets/images/bg20.png" alt=""></p>
                        <em>巨头的入局给汽车行业带来了什么</em>
                        <p>最近几年，汽车前市场，汽车后市场等吸引了从BAT到创业公司的各路玩家进入。<span>2018年04月23日</span></p>
                    </router-link>
                </li>
                <li>
                    <router-link to="/industryDetails" href="#">
                        <p><img src="../assets/images/bg21.png" alt=""></p>
                        <em>滴滴真想抢汽车后市场的钱?</em>
                        <p>面对汽车后服务市场的竞争不断在加剧，线上的滴滴转移到线下。<span>2018年08月09日</span></p>
                    </router-link>
                </li>
                <li>
                    <router-link to="/industryDetails" href="#">
                        <p><img src="../assets/images/bg22.png" alt=""></p>
                        <em>汽配B2B或迎来生死淘汰赛?</em>
                        <p>对于汽配B2B市场而言，京东的进入本已浑浊的市场情况，将变得更加扑朔迷离。<span>2017年12月11日</span></p>
                    </router-link>
                </li>
                <div class="clear"></div>
            </div>

            <div class="pages"  data-aos="fade-up">
                <el-pagination
                small
                layout="prev, pager, next"
                :total="50">
                </el-pagination>
            </div>

        </div>

    </div>
    


</div>
</template>

<script>
export default {
  name: 'index',
  data(){
    return{
        
    }
  },
  methods:{
    damoa(){
        AOS.init({
            duration : 300,
            easing: 'ease-in-sine',  
            delay: 300
        })   
    }
  },
   mounted(){
      this.damoa()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.industry_banner img{width: 100%;}

.industry_big{padding: 80px 0;}
.industry_ul li{width: 32%; float: left; margin-right: 2%; margin-bottom: 2%; overflow: hidden;
}
.industry_ul li:nth-of-type(3n){margin-right: 0;}
.industry_ul li p img{width: 100%; transition: 0.5s all;}
.industry_ul li em{font-size: 16px; color: #333333; display: block; margin: 10px 0 5px 0; font-weight: bold;}
.industry_ul li p{font-size: 14px; width: 100%; color: #707070; display: block; position: relative;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
}
.industry_ul li span{ float: right; display: inline-block; background: #FFFFFF; padding-left: 52px; color: #CECECE;}

.industry_ul li:hover em{color: #057fe2;}
.industry_ul li:hover p img{ transform: scale(1.05);}

.pages{ text-align: center; margin: 30px auto 10px auto;}

.industry_banner span img{display: none;}

@media(max-width:1200px){
    .industry_big{padding: 50px 15px;}
}
@media(max-width:900px){

.industry_big{padding: 40px 15px;}
}
@media(max-width:800px){
    .industry_ul li em{font-size: 14px;
      /* overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical; */
    }
    .industry_ul li span{display: none;}
}

@media(max-width:600px){
    .industry_ul li{width: 49%; margin-right: 2%;}
    .industry_ul li:nth-of-type(3n){margin-right: 2%;}
    .industry_ul li:nth-of-type(2n){margin-right: 0%;}
    .industry_ul li em{font-size: 14px; margin-top: 5px; }
    .industry_ul li p{font-size: 12px; }

  .industry_big{padding: 30px 15px;}

    .industry_banner img{display: none;}
    .industry_banner span img{display: block;}
}

</style>
